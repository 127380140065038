import React, { lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Navbar = lazy(() => import('./components/Navbar/Navbar'));
const Workshop = lazy(() => import('./pages/workshop/Workshop'));
const Internship = lazy(() => import('./pages/internship/Internship'));
const Placement = lazy(() => import('./pages/placement/Placement'));
const Javafsd = lazy(() => import('./pages/courses/javafsd/Javafsd'));
const Pythonfsd = lazy(() => import('./pages/courses/pythonfsd/Pythonfsd'));
const Footer = lazy(() => import('./components/Footer'));
const Robotics = lazy(() => import('./robotics/Robotics'));
const Main = lazy(() => import('./pages/home/Main'));
const Java = lazy(() => import('./pages/courses/java/java'));
const Python = lazy(() => import('./pages/courses/python/python'));
const Reac = lazy(() => import('./pages/courses/react/react'));
const Sql = lazy(() => import('./pages/courses/sql/sql'));
const Mm = lazy(() => import('./pages/courses/Sap_MM/Mm'));
const Sd = lazy(() => import('./pages/courses/Sap_SD/Sd'));
const Fi = lazy(() => import('./pages/courses/Sap_FI/Fi'));
const Aws = lazy(() => import('./pages/courses/Aws/Aws'));
const Azure = lazy(() => import('./pages/courses/Microsoft Azure/Azure'));
const Devops = lazy(() => import('./pages/courses/DevOps/Devops'));
const Project = lazy(() => import('./robotics/Project'));

const Spinner = () => (
  <div className="flex justify-center items-center min-h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
  </div>
);

const App = () => {
  const location = useLocation();
  
  // Hide Navbar and Footer on the / and /project routes
  const hideNavbarAndFooter = location.pathname === '/' || location.pathname === '/project';

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>Qbee Academy</title>
        <meta name="description" content="Qbee Academy offers courses, workshops, internships, and placement opportunities in robotics, software development, and more." />
        <meta name="keywords" content="Qbee Academy, robotics, software development, workshops, internships, placements, Java, Python" />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        {!hideNavbarAndFooter && <Navbar />}
        <Routes>
          <Route path="/" element={<Robotics />} />
          <Route path="/software" element={<Main />} />
          <Route path="/workshop" element={<Workshop />} />
          <Route path="/internship" element={<Internship />} />
          <Route path="/placement" element={<Placement />} />
          <Route path="/courses/javafsd" element={<Javafsd />} />
          <Route path="/courses/pythonfsd" element={<Pythonfsd />} />
          <Route path="/courses/java" element={<Java />} />
          <Route path="/courses/python" element={<Python />} />
          <Route path="/courses/react" element={<Reac />} />
          <Route path="/courses/sql" element={<Sql />} />
          <Route path="/courses/sap_mm" element={<Mm />} />
          <Route path="/courses/sap_sd" element={<Sd />} />
          <Route path="/courses/sap_fi" element={<Fi />} />
          <Route path="/courses/aws" element={<Aws />} />
          <Route path="/courses/azure" element={<Azure />} />
          <Route path="/courses/devops" element={<Devops />} />
          <Route path="/project" element={<Project />} />
        </Routes>
        {!hideNavbarAndFooter && <Footer />}
      </Suspense>
    </div>
  );
};

export default App;
